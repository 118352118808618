import axios from '../request'

// 获取商户提交信息
export const getAcctRegisterInfo = (data) => {
  return axios({
    url: `/account/queryAcctRegisterInfo`,
    method: 'post',
    data
  })
}

// 添加用户获取验证码
export const getMspVerificationCode = (data) => {
  return axios({
    url: `/account/getMspVerificationCode`,
    method: 'post',
    data
  })
}

// 系统支持的语言
export const getSysLanguage = (data) => {
  return axios({
    url: `/account/getSysLanguage`,
    method: 'post',
    data
  })
}

// 更新账户信息
export const updateAcctInfo = (data) => {
  return axios({
    url: `/account/updateAcctInfo`,
    method: 'post',
    data
  })
}

// 获取用户列表
export const getUserList = (data) => {
  return axios({
    url: `/account/getUserList`,
    method: 'post',
    data
  })
}

// 获取手机号区域
export const getPhoneAreaCode = (data) => {
  return axios({
    url: `/basic/queryPhoneAreaCode`,
    method: 'post',
    data
  })
}

// 获取角色ID
export const getUserRoleName = (params) => {
  return axios({
    url: `/account/queryRoleById`,
    method: 'get',
    params
  })
}

// 新增/修改用户信息
export const addOrUpdateUserManage = (data) => {
  return axios({
    url: `/account/addOrUpdateUserManage`,
    method: 'post',
    data
  })
}

// 重置用户密码
export const resetPassword = (params) => {
  return axios({
    url: `/account/resetPassWord`,
    method: 'get',
    params
  })
}

// 获取邮件验证码
export const sendCodeEmail = () => {
  return axios({
    url: `/user/sendPayPwdEmail`,
    method: 'get'
  })
}

// 修改登录密码
export const modifyPassword = (data) => {
  return axios({
    url: `/account/updatePwd`,
    method: 'post',
    data
  })
}

// 设置支付密码
export const setPayPassword = (data) => {
  return axios({
    url: `/user/setPayPassword`,
    method: 'post',
    data
  })
}

// 修改支付密码
export const modifyPayPassword = (data) => {
  return axios({
    url: `/user/changePayPassword`,
    method: 'post',
    data
  })
}

// 支付密码重置
export const resetPayPassword = (data) => {
  return axios({
    url: `/user/resetPayPwd`,
    method: 'post',
    data
  })
}

// 更新账户信息
export const updateKycInfo = (data) => {
  return axios({
    url: `/account/updateKycInfo`,
    method: 'post',
    data
  })
}

// 获取当前用户已保存信息
export const getTemporaryValue = (data) => {
  return axios({
    url: `/basic/getTemporaryValue`,
    method: 'post',
    data
  })
}

// 用户信息暂存
export const setTemporaryValue = (data) => {
  return axios({
    url: `/basic/setTemporaryValue`,
    method: 'post',
    data
  })
}

// 导出商户入网资料模板
export const exportMerchantTemplate = (params) => {
  return axios({
    url: `/user/exportMerchantTemplate`,
    method: 'get',
    params
  })
}

// 获取入网拒绝信息
export const getRejectCustomerKyc = (data) => {
  return axios({
    url: `/merchantRefactor/queryCustomerKyc`,
    method: 'post',
    data
  })
}

// 提交入网商户信息
export const approveMaterial = (data) => {
  return axios({
    url: `/merchantRefactor/approveMaterial`,
    method: 'post',
    data
  })
}

// 入网商户时-msp临时保存VA信息接口
export const saveVaInfoTmp = (data) => {
  return axios({
    url: `/merchantRefactor/saveVaInfoTmp`,
    method: 'post',
    data
  })
}
// 营业执照的OCR
export const imgUrlBusinessOcr = (params) => {
  return axios({
    url: '/ocr/businessOcr',
    method: 'post',
    data: params,
    contentType: 'text/plain'
  })
}
// 身份证正反面的OCR-中国大陆
export const imgUrlCertOcrCN = (params) => {
  return axios({
    url: '/ocr/certOcr',
    method: 'post',
    data: params,
    contentType: 'text/plain'
  })
}
// 身份证正反面的OCR-中国香港
export const imgUrlCertOcrHK = (params) => {
  return axios({
    url: '/ocr/certHkOcr',
    method: 'post',
    data: params,
    contentType: 'text/plain'
  })
}

// 护照的OCR
export const imgUrlPassportOcr = (params) => {
  return axios({
    url: '/ocr/passportOcr',
    method: 'post',
    data: params,
    contentType: 'text/plain'
  })
}
// 汉子转拼音
export const chineseToPinYin = (params) => {
  return axios({
    url: '/cover/chineseToPinYin',
    method: 'post',
    data: params,
    contentType: 'text/plain'
  })
}

// OCR识别
export const passportOcrKyc = (data) => {
  return axios({
    url: `/merchantRefactor/passportOcrKyc`,
    method: 'post',
    data
  })
}

// 根据商户code获取协议
export const getSaasMerAgreement = (data) => {
  return axios({
    url: data.id ? `/saas/getSaasMerAgreement/${data.id}` : '/saas/getSaasMerAgreement',
    method: 'post',
    data
  })
}

export const checkMerchantNameEn = (data) => {
  return axios({
    url: `/merchantRefactor/checkMerchantNameEn`,
    method: 'post',
    data
  })
}

// 调单信息列表
export const getDispatchList = (data) => {
  return axios({
    url: `/dispatch/queryDispatchList`,
    method: 'post',
    data
  })
}

// 调单详情
export const getDispatchDetail = (data) => {
  return axios({
    url: `/dispatch/queryMaterial`,
    method: 'post',
    data
  })
}

// 调单材料提交
export const submitMaterial = (data) => {
  return axios({
    url: `/dispatch/submitMaterial`,
    method: 'post',
    data
  })
}

// 调单材料修改
export const modifyMaterial = (data) => {
  return axios({
    url: `/dispatch/modifyMaterial`,
    method: 'post',
    data
  })
}

// 是否开启安全验证
export const querySafetySwitch = (data) => {
  return axios({
    url: `/merchantRefactor/queryDetail`,
    method: 'post',
    data
  })
}

// 绑定手机号
export const bindMobile = (data) => {
  return axios({
    url: `/merchantRefactor/bindMobile`,
    method: 'post',
    data
  })
}

// 换绑手机号
export const updateBindPhone = (data) => {
  return axios({
    url: `/merchantRefactor/updateBindPhone`,
    method: 'post',
    data
  })
}

// 获取验证码
export const sendVerificationCode = (params) => {
  return axios({
    url: `/merchantRefactor/sendVerificationCode`,
    method: 'post',
    params
  })
}

// 安全验证开关
export const updateSafetyStatus = (data) => {
  return axios({
    url: `/merchantRefactor/updateStatus`,
    method: 'post',
    data
  })
}

// 交易获取验证码
export const transactionTriggeredSms = (data) => {
  return axios({
    url: `/merchantRefactor/transactionTriggeredSms`,
    method: 'post',
    data
  })
}

// 验证验证码
export const verificationCodeCheck = (params) => {
  return axios({
    url: `/merchantRefactor/transactionVerificationCode`,
    method: 'post',
    params
  })
}

// 账单生成提醒
export const checkReportFile = (params) => {
  return axios({
    url: `/balance/checkReportFile`,
    method: 'post',
    params
  })
}

// 证件是否过过期
export const queryCertificateExpired = (data) => {
  return axios({
    url: `/account/queryCertificateExpired`,
    method: 'post',
    data
  })
}

// 过期证件列表
export const queryExpiredInformation = (data) => {
  return axios({
    url: `/account/queryExpiredInformation`,
    method: 'post',
    data
  })
}

// 过期证件更新
export const updateCertificateExpired = (data) => {
  return axios({
    url: `/account/updateCertificateExpired`,
    method: 'post',
    data
  })
}
