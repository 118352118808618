import axios from 'axios'
import i18n from '@/locale/index'
import Cookies from 'js-cookie'
import store from '../store'
import { notification, message } from 'ant-design-vue'
import { localStorageClear } from '@/util'

let timer = null

const node_env = process.env.NODE_ENV

axios.defaults.timeout = 60 * 1000
axios.defaults.withCredentials = true // 跨域请求必需带上凭证
axios.defaults.headers['Content-Type'] = 'application/json'

// 全局拦截请求
// 请求前
axios.interceptors.request.use(function (config) {
  // 在发送请求之前做些什么
  const frontLanguage = localStorage.getItem('locale-language') || 'zh'
  const frontKey = process.env.VUE_APP_SAASNAME !== 'undefined' ? process.env.VUE_APP_SAASNAME : 'OTT'
  config.withCredentials = true
  config.headers['Front-Language'] = frontLanguage
  config.headers['Front-Key'] = frontKey
  if (config.contentType) {
    config.headers['Content-Type'] = config.contentType
  }
  return config
}, function (error) {
  // 对请求错误做些什么
  return Promise.reject(error)
})

// 请求后
axios.interceptors.response.use(function (response) {
  // 上线维护页面跳转
  if (response.headers['server-maintain']) {
    if (window.location.href.indexOf('online_fix') !== -1) {
      window.location.replace(`#/online_fix?onlineTime=${response.headers['server-maintain']}`)
    } else {
      window.location.href = `#/online_fix?onlineTime=${response.headers['server-maintain']}`
    }
    return
  } else {
    // 每次上线完毕，判断前后端版本号是否一致来强制刷新页面
    const { frontProVersion } = store.state.map
    const serverProVersion = response.headers['server-version']
    const isPrevent = Cookies.get('prevent-refresh')
    if (serverProVersion && node_env !== 'development' && !isPrevent && frontProVersion !== serverProVersion) {
      Cookies.set('prevent-refresh', 'true', { expires: 1 / 24 })
      window.location.reload(true)
    }
    // 上线完毕跳转首页
    if (window.location.href.indexOf('online_fix') !== -1) {
      window.location.replace('#/home/index')
    }
  }

  // 对响应数据做点什么
  if (response.status === 200) {
    const data = response.data
    if (data.resCode === '000004') { // 登陆过期
      clearInterval(timer)
      timer = setInterval(() => {
        clearInterval(timer)
        message.error(i18n.global.t('http_text_004'))
      }, 300)
      localStorageClear()
      window.location.href = '#/login'
    } else if (data.resCode === '999999') {
      notification.destroy()
      notification.error({
        message: i18n.global.t('http_text_001')
      })
    }
  }
  return response
}, function (error) {
  // 对响应错误做点什么
  let code = null
  if (error.message.indexOf('6000')) {
    code = '000001'
  } else if (error.message.indexOf('Network')) {
    code = '000002'
  }
  if (code) {
    notification.destroy()
    notification.error({
      message: i18n.global.t('http_text_002') + code + ']'
    })
  } else {
    notification.destroy()
    notification.error({
      message: i18n.global.t('http_text_003') + error
    })
  }
  return error
})

export default axios
