import MainPage from '../pages/index'
import MaterialSubmit from '../pages/globalReceive/manage/materialSubmit'
import i18n from '../locale/index'

const routes = [
  {
    path: '/login',
    name: 'login',
    meta: { title: i18n.global.t('pages_router_001') },
    component: () => import('@/pages/login')
  },
  {
    path: '/register',
    name: 'register',
    meta: { title: i18n.global.t('pages_router_039') },
    component: () => import('@/pages/register')
  },
  {
    path: '/dispatchOrderEdit',
    name: 'dispatchOrderEdit',
    meta: { title: i18n.global.t('pages_router_059') },
    component: () => import('@/pages/userManage/dispatch/orderUpdate')
  },
  {
    path: '/oauth',
    name: 'oauth',
    children: [{
      path: '/oauth/oauthLogin',
      name: 'oauthLogin',
      meta: { title: i18n.global.t('pages_router_045') },
      component: () => import('@/pages/oauth/oauth-login.vue')
    },
    {
      path: '/oauth/oauthSubmit',
      name: 'oauthSubmit',
      meta: { title: i18n.global.t('pages_router_046') },
      component: () => import('@/pages/oauth/oauth-submit.vue')
    }]
  },
  {
    path: '/resetPassword',
    name: 'resetPassword',
    meta: { title: i18n.global.t('pages_router_040') },
    component: () => import('@/pages/resetPassword')
  },
  {
    path: '/user_leave',
    name: 'userLeave',
    meta: { title: i18n.global.t('common_text_054') },
    component: () => import('@/pages/userLeave')
  },
  {
    path: '/online_fix',
    name: 'onlineFix',
    meta: { title: i18n.global.t('common_text_049') },
    component: () => import('@/pages/onlineFix')
  },
  {
    path: '/error_403',
    name: 'error403',
    meta: { title: i18n.global.t('common_text_067') },
    component: () => import('@/pages/errorPages/403')
  },
  {
    path: '/merchant_add',
    name: 'merchantAdd',
    meta: { title: i18n.global.t('pages_router_037') },
    component: () => import('@/pages/merchantAdd/add-info')
  },
  {
    path: '/guide_page',
    name: 'guidePage',
    meta: { title: i18n.global.t('pages_router_038') },
    component: () => import('@/pages/merchantInfo/guide-page')
  },
  {
    path: '/agreement/:key',
    name: 'agreement',
    meta: { title: i18n.global.t('pages_router_051') },
    component: () => import('@/pages/agreement/index.vue')
  },
  {
    path: '/guide_success',
    name: 'guideSuccess',
    meta: { title: i18n.global.t('pages_router_026') },
    component: () => import('@/pages/merchantInfo/guide-success')
  },
  {
    path: '/guide_liveness',
    name: 'guideLiveness',
    meta: { title: i18n.global.t('pages_router_026') },
    component: () => import('@/pages/merchantInfo/guide-liveness')
  },
  {
    path: '/terms_service',
    name: 'termsService',
    meta: { title: i18n.global.t('pages_router_041') },
    component: () => {
      if (localStorage.getItem('locale-language') === 'zh') return import('@/pages/termsService/cn.vue')
      else return import('@/pages/termsService/en.vue')
    }
  },
  {
    path: '/clause_supply',
    name: 'clauseSupply',
    meta: { title: i18n.global.t('pages_router_043') },
    component: () => import('@/pages/termsService/supply')
  },
  {
    path: '/privacy_policy',
    name: 'privacyPolicy',
    meta: { title: i18n.global.t('pages_router_042') },
    component: () => {
      if (localStorage.getItem('locale-language') === 'zh') return import('@/pages/privacyPolicy/cn')
      else return import('@/pages/privacyPolicy/en')
    }
  },
  {
    path: '/use_policy',
    name: 'usePolicy',
    meta: { title: i18n.global.t('pages_router_044') },
    component: () => {
      if (localStorage.getItem('locale-language') === 'zh') return import('@/pages/usePolicy/cn')
      else return import('@/pages/usePolicy/en')
    }
  },
  {
    path: '/merchant_info',
    name: 'merchantInfo',
    meta: { title: i18n.global.t('pages_router_037') },
    component: () => import('@/pages/merchantInfo')
  },
  {
    path: '/:pathMatch(.*)*',
    name: '404',
    component: () => import('@/pages/errorPages/404'),
    meta: { title: i18n.global.t('pages_router_011') }
  },
  {
    order: 1,
    path: '/home',
    name: 'home',
    id: 11,
    icon: require('@/assets/icons/ic-index.png'),
    component: MainPage,
    children: [
      {
        id: 1101,
        meta: { title: i18n.global.t('pages_router_002') },
        path: '/home/index',
        name: 'homeIndex',
        component: () => import('@/pages/homeIndex')
      }
    ]
  },
  {
    order: 2,
    path: '/wallet',
    name: 'wallet',
    id: 12,
    icon: require('@/assets/icons/ic-wallet.png'),
    component: MainPage,
    children: [
      {
        id: 1201,
        meta: { title: i18n.global.t('pages_router_022') },
        path: '/wallet/index',
        name: 'walletIndex',
        component: () => import('@/pages/globalWallet')
      }
    ]
  },
  {
    order: 4,
    path: '/receive',
    name: 'receive',
    id: 13,
    icon: require('@/assets/icons/ic-receive.png'),
    meta: { title: i18n.global.t('pages_router_003') },
    component: MainPage,
    children: [
      {
        id: 1301,
        meta: { title: i18n.global.t('pages_router_006') },
        path: '/receive/manage',
        name: 'receiveManage',
        component: () => import('@/pages/globalReceive/manage')
      },
      {
        id: 1302,
        meta: { title: i18n.global.t('pages_router_004') },
        path: '/receive/account',
        name: 'receiveAccount',
        component: () => import('@/pages/globalReceive/account')
      },
      {
        id: 1303,
        meta: { title: i18n.global.t('pages_router_005') },
        path: '/receive/apply_type',
        name: 'applyFill',
        component: () => import('@/pages/globalReceive/account/applyType')
      },
      {
        id: 1304,
        meta: { title: i18n.global.t('pages_router_005') },
        path: '/receive/e_commerce',
        name: 'applyEcommerce',
        component: () => import('@/pages/globalReceive/account/eCommerceForm')
      },
      {
        id: 1305,
        meta: { title: i18n.global.t('pages_router_005') },
        path: '/receive/t_trade',
        name: 'applyTrade',
        component: () => import('@/pages/globalReceive/account/tTradeForm')
      },
      {
        id: 1306,
        meta: { title: i18n.global.t('pages_router_026') },
        path: '/receive/apply_success',
        name: 'applySuccess',
        component: () => import('@/pages/globalReceive/account/applySuccess')
      },
      {
        id: 1307,
        meta: { title: i18n.global.t('pages_router_009') },
        path: '/receive/material_submit',
        name: 'materialSubmit',
        component: MaterialSubmit,
        children: [
          {
            id: 13071,
            meta: { title: i18n.global.t('pages_router_009') },
            path: '/receive/material_submit/e_commerce/',
            name: 'materialEcommerce',
            component: () => import('@/pages/globalReceive/manage/materialSubmit/eCommerce')
          },
          {
            id: 13072,
            meta: { title: i18n.global.t('pages_router_009') },
            path: '/receive/material_submit/t_trade',
            name: 'materialTrade',
            component: () => import('@/pages/globalReceive/manage/materialSubmit/tTrade')
          }
        ]
      },
      {
        id: 1308,
        meta: { title: i18n.global.t('pages_receive_120') },
        path: '/receive/account_detail',
        name: 'accountDetail',
        component: () => import('@/pages/globalReceive/account/tradeDetail')
      },
      {
        id: 1309,
        meta: { title: i18n.global.t('pages_router_047') },
        path: '/receive/account_holder',
        name: 'accountHolder',
        component: () => import('@/pages/globalReceive/holder')
      },
      {
        id: 1310,
        meta: { title: i18n.global.t('pages_router_048') },
        path: '/receive/holder_edit',
        name: 'holderEdit',
        component: () => import('@/pages/globalReceive/holder/holderEdit')
      }
    ]
  },
  {
    order: 5,
    path: '/exchange',
    name: 'exchange',
    id: 14,
    icon: require('@/assets/icons/ic-tab-exchange.png'),
    meta: { title: i18n.global.t('pages_router_016') },
    component: MainPage,
    children: [
      {
        id: 1401,
        meta: {
          title: i18n.global.t('pages_router_017'),
          auth: true
        },
        path: '/exchange/apply',
        name: 'exchangeApply',
        component: () => import('@/pages/curExchange/apply')
      },
      {
        id: 1402,
        meta: {
          title: i18n.global.t('pages_router_018'),
          auth: true
        },
        path: '/exchange/details',
        name: 'exchangeDetails',
        component: () => import('@/pages/curExchange/details')
      }
    ]
  },
  {
    order: 6,
    path: '/funds',
    name: 'funds',
    id: 15,
    icon: require('@/assets/icons/ic-pay.png'),
    meta: { title: i18n.global.t('pages_router_007') },
    component: MainPage,
    children: [
      {
        order: 1,
        id: 1501,
        meta: {
          title: i18n.global.t('pages_router_021'),
          auth: true
        },
        path: '/funds/inbounds',
        name: 'settleInbounds',
        component: () => import('@/pages/globalPay/inbounds')
      },
      {
        order: 3,
        id: 1502,
        meta: {
          title: i18n.global.t('pages_router_036'),
          auth: true
        },
        path: '/funds/outbounds',
        name: 'foreignOutbounds',
        component: () => import('@/pages/globalPay/outbounds')
      },
      {
        order: 4,
        id: 1503,
        meta: {
          title: i18n.global.t('pages_router_027'),
          auth: true
        },
        path: '/funds/review',
        name: 'settleReview',
        component: () => import('@/pages/globalPay/reviewer')
      },
      {
        order: 5,
        id: 1504,
        meta: { title: i18n.global.t('pages_router_028') },
        path: '/funds/pay_details',
        name: 'payDetails',
        component: () => import('@/pages/globalPay/details')
      },
      {
        order: 6,
        id: 1505,
        meta: { title: i18n.global.t('pages_router_034') },
        path: '/funds/payee_manage',
        name: 'payeeManage',
        component: () => import('@/pages/globalPay/payee')
      },
      {
        order: 7,
        id: 1506,
        meta: {
          title: i18n.global.t('pages_router_029'),
          auth: true
        },
        path: '/funds/withdraw',
        name: 'fundsWithdraw',
        component: () => import('@/pages/globalPay/withdraw')
      },
      {
        order: 8,
        id: 1507,
        meta: {
          title: i18n.global.t('pages_router_030'),
          auth: true
        },
        path: '/funds/account_transfer',
        name: 'accountTransfer',
        component: () => import('@/pages/globalPay/transfer')
      },
      {
        id: 1508,
        meta: {
          title: i18n.global.t('pages_router_024'),
          auth: true
        },
        path: '/funds/inbounds/single_cny',
        name: 'singleCny',
        component: () => import('@/pages/globalPay/inbounds/singleApply')
      },
      {
        id: 1509,
        meta: {
          title: i18n.global.t('pages_router_025'),
          auth: true
        },
        path: '/funds/inbounds/batch_cny',
        name: 'batchCny',
        component: () => import('@/pages/globalPay/inbounds/batchApply')
      },
      {
        id: 1510,
        meta: { title: i18n.global.t('pages_router_026') },
        path: '/funds/apply_success',
        name: 'applyPaySuccess',
        component: () => import('@/pages/globalPay/inbounds/applySuccess')
      },
      {
        id: 1511,
        meta: { title: i18n.global.t('pages_router_052') },
        path: '/funds/inbounds/payee_edit',
        name: 'inboundPayeeEdit',
        component: () => import('@/pages/globalPay/payee/payeeInbounds/payeeEdit')
      },
      {
        id: 1512,
        meta: { title: i18n.global.t('pages_router_035') },
        path: '/funds/payer_manage',
        name: 'payerManage',
        component: () => import('@/pages/globalPay/inbounds/payer')
      },
      {
        id: 1513,
        meta: {
          title: i18n.global.t('pages_router_025'),
          auth: true
        },
        path: '/funds/outbounds/batch_apply',
        name: 'batchOutbounds',
        component: () => import('@/pages/globalPay/outbounds/batchApply')
      },
      {
        id: 1514,
        meta: {
          title: i18n.global.t('pages_router_024'),
          auth: true
        },
        path: '/funds/outbounds/single_apply',
        name: 'singleOutbounds',
        component: () => import('@/pages/globalPay/outbounds/singleApply')
      },
      {
        id: 1515,
        meta: { title: i18n.global.t('pages_router_052') },
        path: '/funds/outbounds/payee_edit',
        name: 'outboundPayeeEdit',
        component: () => import('@/pages/globalPay/payee/payeeOutbounds/payeeEdit')
      },
      {
        order: 2,
        id: 1516,
        meta: {
          title: i18n.global.t('pages_router_053'),
          auth: true
        },
        path: '/funds/rmb',
        name: 'fundsRmb',
        component: () => import('@/pages/globalPay/rmbAccount')
      },
      {
        id: 1517,
        meta: {
          title: i18n.global.t('pages_router_054'),
          auth: true
        },
        path: '/funds/rmb/pay_single',
        name: 'rmbPaySingle',
        component: () => import('@/pages/globalPay/rmbAccount/acctPay/single-apply')
      },
      {
        id: 1518,
        meta: {
          title: i18n.global.t('pages_router_055'),
          auth: true
        },
        path: '/funds/rmb/pay_batch',
        name: 'rmbPayBatch',
        component: () => import('@/pages/globalPay/rmbAccount/acctPay/batch-apply')
      },
      {
        id: 1519,
        meta: {
          title: i18n.global.t('pages_router_056'),
          auth: true
        },
        path: '/funds/rmb/cash_single',
        name: 'rmbCashSingle',
        component: () => import('@/pages/globalPay/rmbAccount/acctCash/single-apply')
      },
      {
        id: 1520,
        meta: {
          title: i18n.global.t('pages_router_057'),
          auth: true
        },
        path: '/funds/rmb/cash_batch',
        name: 'rmbCashBatch',
        component: () => import('@/pages/globalPay/rmbAccount/acctCash/batch-apply')
      }
    ]
  },
  {
    order: 7,
    path: '/account',
    name: 'account',
    id: 16,
    icon: require('@/assets/icons/ic-finance.png'),
    meta: { title: i18n.global.t('pages_router_014') },
    component: MainPage,
    children: [
      {
        id: 1601,
        meta: { title: i18n.global.t('pages_router_015') },
        path: '/account/details',
        name: 'accountDetails',
        component: () => import('@/pages/accountManage/details')
      },
      {
        id: 1602,
        meta: { title: i18n.global.t('pages_router_019') },
        path: '/account/fee_details',
        name: 'feeDetails',
        component: () => import('@/pages/accountManage/fee')
      },
      {
        id: 1603,
        meta: { title: i18n.global.t('pages_router_020') },
        path: '/account/records',
        name: 'accountRecords',
        component: () => import('@/pages/accountManage/records')
      }
    ]
  },
  {
    order: 8,
    path: '/user',
    name: 'userManage',
    id: 17,
    icon: require('@/assets/icons/ic-manage.png'),
    meta: { title: i18n.global.t('pages_router_023') },
    component: MainPage,
    children: [
      {
        id: 1701,
        meta: { title: i18n.global.t('pages_router_031') },
        path: '/user/account_materials',
        name: 'userAccountMaterials',
        component: () => import('@/pages/userManage/materials')
      },
      {
        id: 1702,
        meta: { title: i18n.global.t('pages_router_032') },
        path: '/user/user_manage',
        name: 'userAccountManage',
        component: () => import('@/pages/userManage/manage')
      },
      {
        id: 1703,
        meta: { title: i18n.global.t('pages_router_033') },
        path: '/user/account_security',
        name: 'userAccountSecurity',
        component: () => import('@/pages/userManage/safety')
      },
      {
        id: 1704,
        meta: { title: i18n.global.t('pages_router_058') },
        path: '/user/dispatch_order',
        name: 'userDispatchOrder',
        component: () => import('@/pages/userManage/dispatch')
      },
      {
        id: 1705,
        meta: { title: i18n.global.t('pages_router_059') },
        path: '/user/dispatch_order_edit',
        name: 'userDispatchOrderEdit',
        component: () => import('@/pages/userManage/dispatch/orderUpdate')
      },
      {
        id: 1706,
        meta: { title: i18n.global.t('pages_router_060') },
        path: '/user/cert_update',
        name: 'userCertUpdate',
        component: () => import('@/pages/userManage/materials/certUpdate')
      }
    ]
  },
  {
    order: 3,
    path: '/receipt',
    name: 'receipt',
    id: 18,
    icon: require('@/assets/icons/ic-receipt.png'),
    meta: { title: i18n.global.t('pages_router_050') },
    component: MainPage,
    children: [
      {
        id: 1801,
        meta: { title: i18n.global.t('pages_router_049') },
        path: '/receipt/orders',
        name: 'receiptOrders',
        component: () => import('@/pages/receiptOrders')
      }
    ]
  }
]

export default routes
